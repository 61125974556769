const local = false
const germany = false
const countries = germany
 ? [
    'AT',
    'BE',
    'HR',
    'DK',
    'EE',
    'IT',
    'LV',
    'LT',
    'DE',
    'SK',
    'SI',
    'SE',
    'CH',
    'UK',
    'NL'
   ]
 : ['PL']

module.exports = { local, germany, countries }
